import React from 'react'
import SEO from '../../components/SEO'
import { CONDITION_SLUG, PLAN_ID, currentActiveQuizKey } from '../../constants/localStorage'

import CovidHero from '../../images/conditions/long-covid/long-covid-hero.png'

// Long COVID Components
import LongCovidStats from '../../components/Conditions/LongCovid/LongCovidStats'
import LongCovidSymptoms from '../../components/Conditions/LongCovid/LongCovidSymptoms'
import LongCovidIntegrative from '../../components/Conditions/LongCovid/LongCovidIntegrative'
import LongCovidTreatment from '../../components/Conditions/LongCovid/LongCovidTreatment'
import LongCovidProgram from '../../components/Conditions/LongCovid/LongCovidProgram'
import LongCovidArticles from '../../components/Conditions/LongCovid/LongCovidArticles'
import LongCovidCitations from '../../components/Conditions/LongCovid/LongCovidCitations'

// Common Components
import TrustedByLong from '../../components/Homepage/TrustedByLong'
import ConditionHero from '../../components/Conditions/Common/ConditionHero'
import ConditionCta from '../../components/Conditions/Common/ConditionCta'
import { Condition } from 'goodpath-common'
import { getQuizNavigationState } from '../../components/common/DynamicQuizPlanCTA'

import { useUserContext } from '../../contexts/User/userContext'
import useLocalStorage from '../../utils/useLocalStorage'
import { AuthPage } from '../../components/LayerComponents/UserIdentificationLayer'
import VideoSection from '../../components/Conditions/Common/VideoSection'

const LongCovid = ({ location }) => {
  const { user } = useUserContext()
  const [planId] = useLocalStorage(PLAN_ID)
  const [planCondition] = useLocalStorage(CONDITION_SLUG)
  const [currentQuizConditionSlug] = useLocalStorage(currentActiveQuizKey)
  const { navigationUrl, navigationText } = getQuizNavigationState(
    planId,
    planCondition,
    currentQuizConditionSlug,
    Condition.LONG_COVID,
    '',
    `/quiz/${Condition.LONG_COVID}?authPage=${AuthPage.SIGNUP}`,
    user.isAuth,
    user.enrolled
  )

  return (
    <>
      <SEO
        title="Long-Haul COVID treatment"
        description="Goodpath care for long-haul COVID. Treatments for fatigue, shortness of breath, brain fog, loss of smell, muscle aches."
        location={location}
      />
      {/* Hero */}
      <ConditionHero
        headline="Whole-person care for Long COVID relief."
        description="Goodpath's integrative treatments follow CDC guidelines for best long-haul COVID care."
        ctaText={navigationText}
        ctaLink={navigationUrl}
        image={CovidHero}
        alt="Long COVID."
      />

      {/* Video */}
      <VideoSection title={'How Goodpath approaches Long COVID care'} />

      {/* Stats */}
      <LongCovidStats />

      {/* Symptoms */}
      <LongCovidSymptoms />

      {/* Integrative */}
      <LongCovidIntegrative />

      {/* Treatment */}
      <LongCovidTreatment />

      {/* Trusted by */}
      <TrustedByLong />

      {/* Mega section, box, app, coach */}
      <LongCovidProgram />

      {/* Learn more */}
      <LongCovidArticles />

      {/* CTA */}
      <ConditionCta
        headline="A better plan for employers"
        description="Goodpath's integrative Long COVID care is scalable and helps return employees to work faster."
        ctaLink="/partner"
        ctaText="Request a demo"
      />

      {/* Citations */}
      <LongCovidCitations />
    </>
  )
}

export default LongCovid
