import { Link } from 'gatsby'
import React from 'react'
const h2 = 'text-3xl lg:text-5xl font-bold mb-6 lg:mb-12 leading-tight'

export default function ConditionCta(props) {
  return (
    <>
      <div className="bg-gp-violet">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-24 lg:max-w-8xl">
          <h2 className="mb-4 text-3xl font-bold leading-tight text-center lg:text-5xl text-gp-off-white ">
            {props.headline}
          </h2>
          <p className="w-full max-w-2xl mx-auto mb-6 text-xl leading-normal text-center md:text-2xl text-gp-white">
            {props.description}
          </p>
          <Link
            id={props?.id}
            to={props.ctaLink}
            className="inline-flex px-6 py-5 text-xl font-bold uppercase transition-all duration-300 rounded bg-gp-off-white text-gp-violet lg:text-2xl lg:px-8 lg:py-6 lg:min-h-24 hover:no-underline hover:bg-gp-slate-30 focus:no-underline focus:bg-gp-slate-30"
          >
            {props.ctaText}
          </Link>
        </div>
      </div>
    </>
  )
}
